@import "./node_modules/react-toastify/scss/main";

:root {
  --toastify-color-success: #57d401;
  --toastify-color-error: #ff0000;
}

.Toastify__toast::before {
  content: "";
  width: 5px;
  background-color: var(--toastify-color);
  top: 0;
  bottom: 0;
  left: 0;
  position: absolute;
}
.Toastify__toast {
  border-radius: 0 !important;

  &--success {
    --toastify-color: #57d401;
  }

  &--error {
    --toastify-color: #ff0000;
  }
}

.Toastify__close-button {
  align-self: center !important;
}
