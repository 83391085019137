@import "./variables";

@mixin mobile_width {
    @media (max-width : #{$break-point-mobile}) {
        @content;
    }
}

@mixin tablet_width {
    @media (max-width : #{$break-point-tablet}) {
        @content;
    }
}

@mixin desktop_width {
    @media (min-width : #{$break-point-tablet + 1}) {
        @content;
    }
}

@mixin scrollbar_square {
    &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
        background: transparent !important;
    }

    &::-webkit-scrollbar-track {
        border-radius: 0;
        background: $color-white;
    }

    &::-webkit-scrollbar-thumb {
        background: $color-grey-4;
        border-radius: 0;
        height: 3px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: $color-grey-4;
    }
}
