@import "../abstracts/variables";
@import "../abstracts/mixins";

.flex-spacer {
  flex: 1;
}

.table-responsive {
  margin-bottom: 20px;

  table {
    color: $color-dark;
    margin-bottom: 0;
  }
  @include scrollbar_square;
}

.button {
  height: 44px;
  min-width: 132px;
  padding: 0px 12px;
  border: 1px solid transparent;
  font-size: 16px;
  font-family: $font-family;

  &--blue {
    background-color: $color-blue;
    color: $color-white;

    &:hover {
      background-color: $color-blue-2;
    }

    &:disabled {
      background-color: $color-grey;
      cursor: default;
      &:hover {
        background-color: $color-grey;
        cursor: default;
      }
    }
  }

  &--margin {
    margin-right: 20px;
  }
}

.button-link {
  border: none;
  font-size: 16px;
  text-decoration: underline;
  background-color: $color-white;
  margin-right: 20px;
  font-family: $font-family;

  &:hover {
    background-color: $color-white;
  }

  &--grey {
    color: $color-grey;
  }

  &--blue {
    color: $color-blue;
  }
}

.processing-page {
  background-color: $color-white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 314px;
  font-size: 24px;
  color: $color-grey;

  @include mobile_width {
    font-size: 20px;
  }
}

.checkbox {
  &__container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 44px;
    padding: 0 20px;
    cursor: pointer;

    &:hover {
      background-color: $color-white-3;
    }
  }

  &__label {
    max-width: 90%;

    &:hover {
      cursor: pointer;
    }
  }

  &__checkmark {
    margin-left: auto;
    width: 16px;
    height: 16px;
    background-color: $color-white;
    border: 1px solid $color-grey;
    display: flex;
    align-items: center;
    justify-content: center;

    &--checked {
      background-color: $color-dark-2;
      border: none;
    }

    img {
      width: 8px;
      height: 8px;
    }
  }
}

.waiting-to-complete {
  background-color: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  cursor: auto;
}

.total {
  float: right;
  padding-bottom: 8px;
}

.modal-open {
  padding-right: 0px !important;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none;
}
.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: $color-red !important;
}

.form-control:disabled {
  opacity: 1;
  background-color: $color-white-3;
  cursor: initial;
  color: $color-grey-21;
}

.form-control-checkbox > input:disabled {
  opacity: 1;
  background-color: $color-white-3;
  border: 1px solid $color-grey-19;
  cursor: initial;
}

input:disabled {
  opacity: 1;
  background-color: $color-white-3;
  border: 1px solid $color-grey-19;
  cursor: initial;
}

.custom-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 4px;

  .custom-control-input {
    height: 23px;
    width: 23px;
    margin: 0;
    padding: 0;
    opacity: 1;
    appearance: none;
    border: 1px solid $color-dark;
    border-radius: 3px;
    background: #fff;
    position: relative;
    margin-right: 10px;
    cursor: pointer;
  }

  .custom-control-input:disabled {
    opacity: 1;
    background-color: $color-white-3;
    cursor: initial;
  }

  .custom-control-input:checked {
    border: 1px solid $color-dark;
  }

  .custom-control-input:checked:before,
  .custom-control-input:checked:after {
    content: "";
    position: absolute;
    height: 2px;
    background: $color-dark;
  }

  .custom-control-input:checked:before {
    width: 6px;
    top: 12px;
    left: 4px;
    transform: rotate(44deg);
  }

  .custom-control-input:checked:after {
    width: 12px;
    top: 10px;
    left: 6px;
    transform: rotate(-55deg);
  }

  .custom-control-input:focus {
    outline: none;
  }
}

.custom-checkbox-invalid {
  .custom-control-input {
    border: 1px solid $color-red !important;
  }

  .custom-control-label {
    color: $color-red;
  }
}

input:disabled {
  opacity: 1;
  background-color: $color-white-3;
  border: 1px solid $color-grey-19;
  cursor: initial;
}

.custom-checkbox {
  input:disabled {
    border: 1px solid $color-grey-19 !important;
    color: $color-grey-21;
  }
}

.cursor-default {
  cursor: default;
}

.color-grey {
  color: $color-grey;
}

.font-family {
  font-family: $font-family;
}

@supports (font: -apple-system-body) and (-webkit-appearance: none) {
  .form-control:disabled {
    color: $color-dark-6;
  }
  .custom-checkbox {
    input:disabled {
      color: $color-dark-6;
    }
  }
}

.require {
  color: $color-red;
  position: relative;
  top: -3px;
}

.pointer {
  cursor: pointer;
}

.white-space-break-spaces {
  white-space: break-spaces;
}

.word-break-break-word {
  word-break: break-word;
}

body.modal-open {
  overflow: hidden;
}

.font-error {
  color: $color-red;
}

.text-over-width {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.color-green {
  color: $color-green;
}

.color-yellow {
  color: $color-yellow;
}

.color-blue {
  color: $color-blue;
}

.text-right {
  text-align: right !important;
}

.icon-info {
  font-size: 27px;
  color: $color-purple;
  margin-right: 0 !important;
}

.invalid-msg {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: $color-red;
}

.placeholder-dropdown {
  color: $color-grey;
  opacity: 0.5;
}

.bg-color {
  &__Approved {
    background-color: $color-green-2;
  }
  &__Submitted {
    background-color: $color-yellow-3;
  }
  &__Rejected {
    background-color: $color-red-1;
  }
  &__Draft {
    background-color: $color-blue-7;
  }
  &__Published {
    background-color: $color-blue-8;
  }

  &__Approved,
  &__Rejected,
  &__Draft,
  &__Submitted,
  &__Published {
    display: flex;
    width: 120px;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    color: $color-white;
    height: 30px;
  }
}
.ant-select {
  &.ant-select-disabled {
    .ant-select-selector,
    .ant-select-selection-item {
      color: $color-grey-21 !important;
    }
  }
}

.check_length {
  font-size: 14px;
  float: right;
  color: $color-grey;
}
