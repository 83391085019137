@import "../abstracts/variables";

body {
  font-size: 16px;
  font-family: Ubuntu, sans-serif;
}

.page-title {
  font-family: $font-family-bold;
  font-size: 28px;
}

.page-subtitle {
  font-family: $font-family;
  font-size: 16px;
}
